@import "src/commonstyle.scss";
@-webkit-keyframes fadeIn{ 0%{opacity:0}100%{opacity:1}}
  @-moz-keyframes fadeIn{0%{opacity:0}100%{opacity:1}}
  @-o-keyframes fadeIn{0%{opacity:0}100%{opacity:1}}
  @keyframes fadeIn{0%{opacity:0}100%{opacity:1}}
  .animated.fadeIn{-webkit-animation-name:fadeIn;-moz-animation-name:fadeIn;-o-animation-name:fadeIn;animation-name:fadeIn}

.theme-header-text-color {
  color: #000 !important;
}

.model-7 .checkbox input:checked+label:after {
  /* / background: #3eb454; / */
  left: 35px;
}

.checkbox {
  position: relative;
  display: inline-block;
}

.checkbox:after,
.checkbox:before {
  font-family: FontAwesome;
  font-feature-settings: normal;
  font-kerning: auto;
  font-language-override: normal;
  font-stretch: normal;
  font-style: normal;
  font-synthesis: weight style;
  font-variant: normal;
  font-weight: normal;
  text-rendering: auto;
}

.checkbox label {
  width: 55px;
  height: 42px;
  background: #ccc;
  position: relative;
  display: inline-block;
  border-radius: 46px;
  transition: 0.4s;
}

.checkbox label:after {
  content: '';
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  left: 0;
  top: -5px;
  z-index: 2;
  background: #fff;
  ;
  box-shadow: 0 0 5px rgba(0, 0, 0, .2);
  transition: 0.4s;
}

.checkbox input {
  position: absolute;
  left: 35%;
  top: -15%;
  width: 100%;
  height: 100%;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}

.checkbox input:hover+label:after {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .2), 0 3px 8px 0 rgba(0, 0, 0, .15);
}

.checkbox input:checked+label:after {
  left: 40px;
}

/* .billing-cycle .checkbox label {
    margin-bottom: -6px;
} */

.model-7 .checkbox label {
  background: #BEEB20;
  height: 22px;
}

.model-7 .checkbox label:after {
  background: #fff;
  top: 3px;
  left: 4px;
  width: 15px;
  height: 15px;
}

#logo {
  background: {
    repeat: no-repeat;
    size: 100%;
  }
  cursor: pointer;
  height: 45px !important;
  width: 135px;
  display: block;
  margin-top: 15px;
  background-image: url(/assets/img/iomLogoBlack.svg);
}

.subject-div {
  // background-color: $content-bg-color;
  // color: #fff;

  .subject {
    a {
      color: #101010;
      font-weight: initial;
    }
  }
}

#fullscreen {
  .svg-icon {
    background : {
      repeat: no-repeat;
      position: center;
    }

    width: 60px !important;
    height: 45px !important;
    margin-top: -2px;
  }
}

#fullscreen {
  .svg-icon {
    background-image: url(/assets/img/iconSvg/fullscreen.svg);
  }
}
.header-dropdown-list {
  margin-bottom: 10px;
  padding-inline-start: 0px;
}
.dropdown-menu {
  padding: 4px;
  min-width: 8rem;
}
.menuSelected {
  background-color: #ededed;
}
.notification-svg {
  background-image: url(/assets/img/iconSvg/39-Notification.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 60px !important;
  height: 45px !important;
}

#activity.activity-svg {
  background-image: url(/assets/img/iconSvg/Page-1.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 60px !important;
  height: 45px !important;
}
.ajax-dropdown {
  position: absolute;
  display: none;
  z-index: 905;
  top: 48px;
  left: 16px;
  width: 344px;
  height: 435px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -webkit-box-shadow: 0 2px 4px rgb(30 30 100 / 25%);
  -moz-box-shadow: 0 2px 4px rgba(30,30,100,.25);
  box-shadow: 0 2px 4px rgb(30 30 100 / 25%);
  padding: 10px;
  background: #fff;
  border: 1px solid #b3b3b3;
}
.ajax-dropdownNoRight{
  right: 0px !important;
}
.dropdown-menu {
  border-radius: 10px;
}
.articleImg{
  width: -webkit-fill-available !important;
}
.articlesubImg{
  width: 145%;
}
.ediImg{
  // width: -webkit-fill-available !important;
  width: 125%;
}

.ac_link{
  color: skyblue !important;
  cursor: pointer;
}
.bs_link{
  color: skyblue !important;
  text-decoration: underline;
  cursor: pointer;
}
.bs_link:hover{
  color: skyblue !important;
  text-decoration: underline !important;
  cursor: pointer;
}
.accept_1 {
  float: left;
  width: 49%;
}
.accept_2 {
  float: right;
  width: 49%;
  margin-left: 2%;
}
.w-70 {
  width: 70%;
}

  // #### dark theme starts here ####

  // .smart-dark {
  //   .header {
  //     background-color: $content-bg-color;
  //     color: $primary-color !important;

  //     .theme-header-text-color {
  //       color: $primary-color !important;
  //     }

  //     #logo {
  //       background-image: url(/assets/img/full_logo_white.png);
  //     }

  //     .header-dropdown-list .dropdown-menu li a:hover {
  //       background-color: $secondaryFocus;
  //     }

  //     .dropdown-menu {
  //       background-color: #0C1214 !important;
  //       color: $primary-color;
  //       min-width: 6rem !important;
  //     }
  //   }

  //   .subject-div {
  //     background-color: $content-bg-color;
  //     color: #fff;
  //     .subject{
  //       a{
  //         color: #101010;
  //       }    
  //     }
  //   }

  //   .footer-section {
  //     background-color: $content-bg-color;
  //     color: #fff;

  //     .links {
  //       color: #fff;

  //       li {
  //         a {
  //           color: #fff;
  //         }
  //       }
  //     }
  //   }

  // }

  // .smart-white {
  //   .header {
  //     background-color: #F7F7F7;
  //     color: #4C4F53 !important;

  //     .theme-header-text-color {
  //       color: #4C4F53 !important;
  //     }

  //     #logo {
  //       background-image: url(/assets/img/logo.png);
  //     }

  //     .header-dropdown-list .dropdown-menu li a:hover {
  //       background-color: $secondaryFocus;
  //     }

  //     .dropdown-menu {
  //       background-color: $primary-color !important;
  //       color: #000;
  //       min-width: 6rem !important;
  //     }
  //   }

  //   .subject-div {
  //     color: #fff;
  //     .subject{
  //       a{
  //         color: #fff;
  //       }    
  //     }
  //   }
  // }
