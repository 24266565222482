/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'Aileron';
  src: url(/assets/css/aileron.regular.otf);
}

body {
  font-family: 'Aileron';
  color: #101010;
  min-height: auto;
  overflow: hidden;
}

a,
a:hover {
  text-decoration: none;
  color: #101010;
}

.row {
  margin: 0px;
}

.width-100 {
  width: 100%;
}

.flag {
  width: 16px;
  height: 11px;
  background: url(/assets/img/flags/flags.png) no-repeat;
  margin-top: -0.18em;
  display: inline-block;
}

.flag.flag-us {
  background-position: -144px -154px
}

.flag.flag-de {
  background-position: -80px -33px
}

.flag.flag-fr {
  background-position: -144px -44px
}

ul {
  list-style-type: none;
}
.menuIconsOption img {
  filter: brightness(100) invert(1);
}
#logo {
  background-image: url(/assets/img/logo.png);
  margin-top: 4px;
}
// .smart-style-1 {
	#logo-group {
		background: unset !important;
    display: flex;
	}
	// #logo {
	// 	background-image: url(/assets/img/full_logo_white.png);
  // }
// }
// .smart-style-2 {
	// #logo {
	// 	background-image: url(/assets/img/logo.png);
	// 	margin-top: 4px;
	// }
// }